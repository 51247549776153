import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IdentityService {
    requiredRoles = ['Rsa.Default', 'nevisMeta.admin'];
    constructor(private http: HttpClient) {}

    public user$ = this.http
        .get<Me>(`${environment.http.users}users/me`, {
            params: {
                sections: ['identity', 'roles'],
            },
        })
        .pipe(shareReplay());

    public hasAccess$ = this.user$.pipe(
        map((user) => {
            const identityType =
                user.properties
                    .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                    ?.value?.toLowerCase() || '';
            return (
                identityType?.startsWith('vi') &&
                this.requiredRoles.some((role) => user.applicationRoles?.includes(role))
            );
        })
    );
}

export interface Me {
    id: string;
    loginId: string;
    name: {
        title: string;
        firstName: string;
        familyName: string;
    };
    address: {
        street: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        countryCode: string;
    };
    company: {
        name: string;
        countryCode: string;
        id: string;
        vkorg: string;
    };
    contacts: {
        email: string;
        mobile: CustomerContactData | string;
        telephone: CustomerContactData | string;
    };
    userState: string;
    languageCode: string;
    locale: string;
    properties: { name: string; value: string }[];
    applicationRoles?: string[];
    businessRoles?: string[];
    contactId?: string;
}

export interface CustomerContactData {
    country: string;
    number: string;
    extension?: string;
}
