<div class="h-100 d-flex flex-column">
    <div class="flex-grow-0">
        <app-header></app-header>
    </div>
    <div class="flex-grow-1 bg-grey-light pb-5 content">
        <router-outlet></router-outlet>
    </div>
    <div class="flex-grow-0">
        <app-footer></app-footer>
    </div>
</div>
