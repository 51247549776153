import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, Observable, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
    private readonly apiHost: string;
    constructor(private auth: AuthService) {
        this.apiHost = new URL(environment.apiUrl).host;
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.includes(this.apiHost)) {
            return next.handle(req);
        }
        return this.auth.csrfToken$.pipe(
            first((val) => val !== undefined),
            switchMap((value) =>
                next
                    .handle(
                        req.clone({
                            headers: req.headers.set('Authorization', `CSRF ${value}`),
                        })
                    )
                    .pipe(
                        catchError((e) => {
                            if (e instanceof HttpErrorResponse && e.status === 401) {
                                // refresh CSRF token and retry active request if fetching of token succeed
                                return this.auth.fetchNewCsrfToken().pipe(switchMap(() => this.intercept(req, next)));
                            }
                            throw e;
                        })
                    )
            )
        );
    }
}
