import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from '../../../environments/environment';
import { HeaderComponent } from './header.component';

const material = [MatToolbarModule, MatIconModule, MatButtonModule];

@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [
        ...material,
        CommonModule,
        GlobalHeaderModule.forRoot({
            appId: 'admin-profi',
            baseUrl: environment.auth.baseUrl,
        }),
        RouterModule,
    ],
})
export class HeaderModule {}
