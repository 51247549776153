<mat-toolbar>
    <mat-toolbar-row class="justify-content-between">
        <div>
            <img src="../../../assets/images/viessmann-white.svg" class="logo-img" alt="" />
            <span class="title ms-3">DevPortal-Trial</span>
        </div>
        <div class="d-flex flex-row">
            <vih-app-switcher></vih-app-switcher>
            <vih-login></vih-login>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
