import { appVersion } from './app-version';
import { Environment } from './environment.model';

export const environment: Environment = {
    env: 'local',
    production: true,
    version: appVersion,
    apiUrl: 'https://api-integration.viessmann.com/',
    accountUrl: 'https://account-integration.viessmann.com/personal-data',
    auth: {
        appId: 'devportal-trial',
        baseUrl: 'https://api-integration.viessmann.com',
    },
    http: {
        baseUrl: 'https://api-integration.viessmann.com/backend/admin-profi/v1/',
        users: 'https://api-integration.viessmann.com/users/v1/',
        purchases: 'https://api-integration.viessmann.com/purchases/v1/',
        auth: 'https://api-integration.viessmann.com/auth/v1',
    },
};
