import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { IdentityService } from './identity.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private identityService: IdentityService, private router: Router) {}

    canActivate(): Observable<boolean> {
        if (this.auth.hasValidCsrfToken()) {
            this.identityService.hasAccess$.subscribe((hasAccess) => {
                if (!hasAccess) {
                    this.router.navigate(['/forbidden']);
                }
            });

            return of(true);
        }
        // start csrf token authentication process
        return this.auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
    }
}
