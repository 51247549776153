import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/overview/overview.module').then((m) => m.OverviewModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'forbidden',
        loadChildren: () => import('./modules/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
